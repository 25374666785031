import * as React from "react";
import { graphql } from "gatsby";
import Navbar from "../components/navbar";
import styled from "@emotion/styled";
import Footer from "../components/footer";
import {
  Jumbotron,
  JumbotronContent,
  JumbotronH1,
  JumbotronH2,
} from "../components/jumbotron";
import { Main } from "../components/main";
import _ from "lodash";
import SEO from "../components/seo";

const RED = "rgb(227, 80, 96)";

const FaqContainer = styled.div`
  border-radius: 25px;
  position: relative;
`;

const FaqTitle = styled.a`
  cursor: pointer;
  font-weight: bold;
  font-size: 16px;
  color: rgba(90, 90, 90);
`;

const FaqArrowOff = styled.a`
  display: block;
  border-radius: 100%;
  border: 1px solid gray;
  width: 28px !important;
  height: 28px !important;
  flex: 0 0 28px;
  text-align: center;
`;
const FaqArrowOn = styled(FaqArrowOff)`
  border: 2px solid ${RED};
  margin-top: -1px;
  margin-left: -1px;
`;

function SvgArrow({ on }: { on?: boolean }) {
  return (
    <svg
      width="14px"
      height="24px"
      viewBox="0 0 64 32"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xlink="http://www.w3.org/1999/xlink"
    >
      <g
        id="Page-1"
        stroke="none"
        stroke-width="4"
        fill="none"
        fill-rule="evenodd"
      >
        <g
          id="Artboard"
          transform="translate(0.000000, -16.000000)"
          stroke={on ? RED : "#979797"}
        >
          <polyline id="Path" points="0 16 32 48 64 16"></polyline>
        </g>
      </g>
    </svg>
  );
}

function FaqItem({ item }) {
  const [visible, setVisible] = React.useState(false);
  return (
    <FaqContainer
      className="card shadow mb-4"
      style={{ cursor: "pointer" }}
      onClick={e => {
        e.preventDefault();
        setVisible(!visible);
      }}
    >
      <div className="card-body">
        <div className="d-flex justify-content-between">
          <FaqTitle dangerouslySetInnerHTML={{ __html: item.h2 }} />
          {visible ? (
            <FaqArrowOn>
              <SvgArrow on />
            </FaqArrowOn>
          ) : (
            <FaqArrowOff>
              <SvgArrow />
            </FaqArrowOff>
          )}
        </div>
        <div
          className={visible ? "mt-3 mb-0 pb-0" : "d-none"}
          dangerouslySetInnerHTML={{ __html: item.content }}
        />
      </div>
    </FaqContainer>
  );
}

export default function Template({ data }) {
  const { markdownRemark } = data;
  const { frontmatter, html } = markdownRemark;
  const parts: string[] = html.split("<h2>");
  const before = parts[0];
  const faqItems = _.drop(parts, 1).map(part => {
    const [h2, content] = part.split("</h2>");
    return { h2, content };
  });
  const half = Math.ceil(faqItems.length / 2);
  return (
    <>
      <SEO
        lang="fr"
        description={frontmatter.description}
        title={frontmatter.title}
        meta={[{ name: "keywords", content: frontmatter.keywords }]}
      />
      <Navbar />
      <div className="container">
        {/* JUMBOTRON */}
        <Jumbotron
          image={
            (frontmatter.banner &&
              frontmatter.banner.childImageSharp.fluid.src) ||
            ""
          }
        >
          <JumbotronContent>
            <div>
              <JumbotronH1>
                Foire aux questions
                <JumbotronH2>Nous répondons à toutes vos questions</JumbotronH2>
              </JumbotronH1>
            </div>
          </JumbotronContent>
        </Jumbotron>
        {/* CONTENT */}
        <Main>
          <div className="mb-4" dangerouslySetInnerHTML={{ __html: before }} />
          <div className="row" style={{ position: "relative" }}>
            <div
              className="d-none d-md-block"
              style={{
                borderRight: "1px solid " + RED,
                position: "absolute",
                top: 0,
                left: "50%",
                height: "100%",
              }}
            >
              <div
                style={{
                  borderRadius: "100%",
                  position: "absolute",
                  top: 0,
                  left: "-6px",
                  height: "13px",
                  width: "13px",
                  backgroundColor: RED,
                }}
              ></div>
            </div>
            <div className="col-lg-6 py-md-5 pr-md-4">
              {faqItems.slice(0, half).map((item, i) => (
                <FaqItem key={i} item={item} />
              ))}
            </div>
            <div className="col-lg-6 py-md-5 pl-md-4">
              {faqItems.slice(half).map((item, i) => (
                <FaqItem key={i} item={item} />
              ))}
            </div>
          </div>
        </Main>
      </div>
      <Footer />
    </>
  );
}

export const pageQuery = graphql`
  query($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        path
        title
        subtitle
        category
        keywords
        description
        banner {
          childImageSharp {
            fluid(maxWidth: 1100, maxHeight: 400) {
              src
            }
          }
        }
      }
    }
  }
`;
